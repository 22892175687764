
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { setTitle, handle429 } from '@util/util.ts';
import ArrowSvg from '@svg/Arrow.vue';
import Thumb from '@part/elements/Thumb.vue';
import axios from 'axios';
import Map from '@part/elements/Map.vue';
import TextBlock from '@part/blocks/TextBlock.vue';
import OverviewContainer from '@part/elements/OverviewContainer.vue';
import { app } from '@config';

/* -------------  ---------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    OverviewContainer,
    TextBlock,
    Thumb,
    Map,
    ArrowSvg
  },
  data() {
    return {
      pageData: {},
      ambassadorData: {},
      museumCoords: [],
    };
  },
  mounted() {
    axios
      .get(`${app.api.api}/page/${this.$route.meta.id}`)
      .then(response => {
        this.pageData = response.data;
        setTitle(response);
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });

    axios
      .get(`${app.api.api}/feed/ambassadors`)
      .then(response => {
        this.ambassadorData = response.data;
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class AmbassadorOverview extends Vue {}
